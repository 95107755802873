/**
 * All-tab screen on Accessioning Page
 */

import React, {useState, useMemo, useEffect} from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel
} from "@tanstack/react-table";
import * as XLSX from 'xlsx';
import '../accessioning.css';
import {
  ACCESSIONING_TYPE__CLINICAL,
  ACCESSIONING_TYPE__QC,
  ACCESSIONING_TYPE__RESEARCH,
  ACCESSIONING_TYPE__OTHER_DNA
} from "../common/accessioningTypes";


// tanstack headless UI components -- see tanstack.com for docs, etc.
import Pagination from "./Pagination";

// Fake data - if needed for testing
// import {data} from "../../../accession_data";

import {Card, CardHeader, Col, Container, Row} from "reactstrap";
import {Button, Classes, Dialog, InputGroup} from "@blueprintjs/core";
import { Button as RSButton } from 'reactstrap';
import table from "reactstrap/es/Table";
import axios from "axios";

import {DebouncedInput, displayDateMMDDYY, getUserToken} from "../common/utils";
import BatchEntryForm from "./BatchEntryForm/index";
import {linkExistingAccessioningBatchToCase} from "../common/LinkAccessioningBatchToCase";
import {getColumnDefinitions} from "./AllTabColumnDefs";
import moment from "moment-timezone";

/**
 * Compute the sum of sample-quantity column.  Only sum from filtered rows currently showing.
 * @param table - tanstack table instance
 * @returns number - sum of sample-quantity column values of filtered rows currently showing
 */
const sumSampleQuantity = (table) => {
  const filteredRowModel = table.getFilteredRowModel();
  if (!('rows' in filteredRowModel)) return 0;
  return filteredRowModel.rows.reduce((total, row) => total + row.getValue('sample_quantity'), 0);
}

const yesterday = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return yesterday.toISOString().split('T')[0];
}

// const aWeekAgo = () => {
//   const today = new Date();
//   const yesterday = new Date(today);
//   yesterday.setDate(today.getDate() - 7);
//   return yesterday.toISOString().split('T')[0];
// }

/**
 * All-Tab screen for Accessioning - shows all Accessioning Batches in all states
 * @param {function} setTotalSampleQuantity - state hook fn to set totalSampleQuantity
 */
export default function AllTab({setTotalSampleQuantity  /*, invokedID, setInvokedID */}) {
  const pagination = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: 10,
    }),
    [],
  );

  // list of accessioning batches
  const [accessioningBatchesList, setAccessioningBatchesList] = useState([]);

  // Sorting state for each column of tanstack table.  Initially sort by batch id descending
  const [sorting, setSorting] = useState([{id: 'id', desc: true}]);

  // which columns are currently showing on screen (true) or are hidden (false)
  const [columnVisibility, setColumnVisibility] = useState({
    id: true,
    case_id: true,
    potential_case_id: false,   // shown only when user has case-match filter checked
    clinic: true,               // hidden when case-match filter checked
    sample_type: true,
    accessioning_type: true,
    received_at: true,
    updated_at: true,
    preamp_date: true,
    axiom_date: true,
    accessioning_status: true,
    sample_quantity: true
  });
  const [columnFilters, setColumnFilters] = useState([]);  // each column's filtering in tanstack table

  // ids of which columns are currently showing their features (sorting and/or filtering) in header
  const [showColumnFeatures, setShowColumnFeatures] = useState([]);

  // is screen showing batch list (false), or potential matching cases (true) ?
  const [isCaseMatchChecked, setIsCaseMatchChecked] = useState(false);

  const [isSearchResultDialogOpen, setIsSearchResultDialogOpen] = useState(false);
  // Value user entered into search box at top
  const [searchValue, setSearchValue] = useState('');
  // Is search result load in progress?
  const [searchResultLoading, setSearchResultLoading] = useState(false);
  // Accessioning batches in search result
  const [searchResultBatches, setSearchResultBatches] = useState([]);

  // has user clicked the +Add button?
  const [addBatchDropdownOpen, setAddBatchDropdownOpen] = useState(false);  // +Add button

  // Modal for entering embryonic and other dna batches.  Values:
  //        '' - hide modal
  //        ACCESSIONING_TYPE - display modal; entry of new accessioning batch of given type
  //        batch id - display modal; edit accessioning batch with given id
  const [showBatchEntryModal, setShowBatchEntryModal] = useState('');

  // If linking in progress, don't allow user to press link button
  const [linkingInProgress, setLinkingInProgress] = useState(false);

  const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
  const [exportStartDate, setExportStartDate] = useState(yesterday());
  const [exportEndDate, setExportEndDate] = useState(yesterday());
  const [exportStatus, setExportStatus] = useState(null); // feedback to user on export dialog

  // Retrieve all batches from back-end db
  const loadAllBatches = () => {
    const token = getUserToken();
    if (!token) return;

    axios({
      url: 'accessioning/list_for_all_tab/',  // url must end in slash
      method: 'GET',
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      headers: {
        "Authorization": `Token ${token}`
      }
    }).then((response) => {
      setAccessioningBatchesList(response.data);
    }).catch((error) => {
      console.error('GET accessioning/list failed', error);
    });
  };


  // on initialization, load list of all batches into accessioningBatchesList
  useEffect(() => loadAllBatches(), []);


  /**
   * User wants to view/edit batch on current row.
   * They either clicked its id or selected Edit from kebab menu
   * @param {number, object} v - if user clicked id (or linked via accessioning/id), v is id of batch to edit.
   *                             if user selected Edit from kebab menu, v is row of accessioning batch list's data.
   */
  const handleTableRowEdit = (v) => {
    const batchId = typeof v === "number" ? v : v.id;
    setShowBatchEntryModal(batchId);
  };

  /**
   * User selected Delete from batch row's kebab menu
   * @param {object} row - data object of current row
   */
  const handleTableRowDelete = (row) => {alert('Delete ' + row.id)};

  /**
   * User selected Log from batch row's kebab menu
   * @param {object} row - data object of current row
   */
  const handleTableRowLog = (row) => {alert('Log ' + row.id)};

  /**
   * User has clicked link icon on potential case-matching screen, indicating they want to link case to batch
   * @param {object} info - tanstack table
   * @param {number} patientId - case id; i.e. row id of patient table
   */
  const handleCaseLink = (info, patientId) => {
    if (linkingInProgress) return;
    setLinkingInProgress(true);
    linkExistingAccessioningBatchToCase(info.row.getValue('id'), patientId).then(response => {
      alert('link successful');  // TODO: make a better alert
      setAccessioningBatchesList(oldList => oldList.filter((_, idx) => idx !== info.row.index)) // delete row
      setLinkingInProgress(false);
    }).catch(error => {alert('an error occurred: ' + error)})
  }

  /**
   * Function to let Column def function look at linkingInProgress
   * (used to enable/disable link buttons)
   * @returns {boolean}
   */
  const getLinkingInProgress = () => linkingInProgress;

  // On initialization, get tanstack table column definitions
  const columnDefinitions = React.useMemo(() =>
    getColumnDefinitions(handleCaseLink, getLinkingInProgress, handleTableRowEdit, handleTableRowDelete, handleTableRowLog)
    ,[]);   // eslint-disable-line

  // definition for tanstack table that holds list of all batches
  const AllBatchTSTable = useReactTable({
    data : accessioningBatchesList,
    columns: columnDefinitions,
    initialState: {
      pagination
    },
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: {},
    state: {
      sorting,
      columnFilters,
      columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });


  // Set the total of all sample quantities -- shared
  // https://profy.dev/article/react-useeffect-with-object-dependency
  useEffect(() => {
    setTotalSampleQuantity(sumSampleQuantity(AllBatchTSTable));
  }, [...Object.values(AllBatchTSTable)]);  // eslint-disable-line

  // Reset all filtering, sorting, et al. of tanstack table
  const resetAll = () => {
    AllBatchTSTable.resetColumnFilters(true);
    AllBatchTSTable.getColumn('id').toggleSorting('desc');
    setShowColumnFeatures([]);  // hide all column header features
  };

  /**
   * Toggle screen between normal batch list and potential case matches display.
   * Called from case-matchup checkbox on-change
   * @param {boolean} toggle - is checkbox checked?
   */
  const setCaseMatchup = toggle => {
    if (!toggle) {
      //
      // Restore normal view (remove "case-matchup filter")
      //
      setColumnVisibility(old => ({
        ...old,
        case_id: true,
        potential_case_id: false
      }));
      loadAllBatches();
      setIsCaseMatchChecked(false);
    } else {
      //
      // Show Case-Matchup "filter"
      //
      const token = getUserToken();
      if (!token) return;

      // GPCL_ID # case id # clinic (provider) id
      const composeMatchupString = (matchupMapRow) => (
        matchupMapRow['first_tube_label'].split('-')[0] + '#' + matchupMapRow['case_id'] + '#' + matchupMapRow['provider_id']
      )

      axios ({
        url: 'accessioning/list_potential_case_matchups/',  // url must end in slash
        method: 'GET',
        responseType: 'json',
        baseURL: process.env.REACT_APP_API_HOST,
        headers: {
          "Authorization": `Token ${token}`
        }
      }).then((response) => {
        const matchupMap = {};
        response.data.forEach(row => matchupMap[row["batch_id"]] = composeMatchupString(row));
        const newList = accessioningBatchesList.filter(row => row["id"] in matchupMap).map(row => {
          return {...row, potential_case_id: matchupMap[row["id"]]};
        })
        if (newList.length > 0) {
          setColumnVisibility(prevState => ({
            ...prevState,
            case_id: false,
            potential_case_id: true,
          }));
          setAccessioningBatchesList(newList);
          setIsCaseMatchChecked(true);
        } else {
          alert("No new matching cases found");
        }
      }).catch((error) => {
        console.error('GET accessioning/case-matchup-list failed', error);
      });
    }
  };

  const submitSearchRequest = (search) => {
    setSearchResultLoading(true);

    axios({
      method: 'POST',
      data: { name: searchValue },
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      url: `accessioning-search/`,
      headers: {
        "Authorization": `Token ${getUserToken()}`
      }
    }).then((resp) => {
      if (resp.data) {
        if (resp.data.batches) {
          setSearchResultBatches(resp.data.batches);
        }

        setIsSearchResultDialogOpen(true);
      }
    })
    .catch((e) => {
      // setError('Error. Check the connection')
    })
    .finally(() => {
      setSearchResultLoading(false);
    });
  }

  /**
   * User has requested export of accessioning batches to Excel
   */
  const doExport = async (exportFileHandle) => {
    setExportStatus('processing');

    const fields = {
      'id': {heading: 'Accessioning#', novalue: '??'},
      'sample_type': {heading: 'Sample Type', novalue: 'N/A'},
      'subject_name': {heading: 'Subject Name', novalue: 'Not provided'},
      'subject_dob': {heading: 'Subject DOB', novalue: 'Not provided'},
      'clinic': {heading: 'Clinic Name', novalue: 'N/A'},
      'gpid': {heading: 'GP ID', novalue: 'N/A'},
      'case_id': {heading: 'Case ID', novalue: 'N/A'},
      'tube_id': {heading: 'Tube ID', novalue: 'N/A'},
      'buffer_lot': {heading: 'Buffer Tube Lot Number', novalue: 'N/A'},
      'time_accessioned': {heading: 'Time Accessioned', novalue: 'N/A'},
      'QC': {heading: 'Sample Condition', novalue: 'N/A'},
      'tests_performed': {heading: 'Test Performed', novalue: 'N/A'},
      'patient_name': {heading: 'Patient Name', novalue: 'N/A'},
      'technician': {heading: 'Technician', novalue: 'N/A'},
      'note': {heading: 'Notes', novalue: ''},
    };

    const testsPerformed = (item) => {
      if (item.m2) return 'M2';
      const resultArray = [];
      if (item.pgt_a) resultArray.push('PGT-A');
      if (item.pgt_a_plus) resultArray.push('PGT-A+');
      if (item.pgt_m) resultArray.push('PGT-M');
      if (item.pgt_p) resultArray.push('PGT-P');
      if (item.pgt_s) resultArray.push('PGT-SR');
      if (resultArray.length === 0) return 'N/A';
      if (resultArray.length === 1) return resultArray[0];
      if (resultArray[0] === 'PGT-A') resultArray.splice(0, 1); // Don't show PGT-A with other PGT tests
      return resultArray.join(',');
    }

    const token = getUserToken();
    axios ({
      url: 'accessioning-export/',  // url must end in slash
      method: 'POST',
      data: { startDate: exportStartDate, endDate: exportEndDate },
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      headers: {
        "Authorization": `Token ${token}`
      }
    }).then(async (response) => {
      if (response.data.length === 0) {
        setExportStatus('none');
        return;
      }
      const preparedData = response.data.map((item) => {
        const row = {};
        Object.keys(fields).forEach((fieldname) => {
          if (fieldname === 'subject_name' || fieldname === 'patient_name')
            row[fields[fieldname]['heading']] = item[fieldname] > ' ' ? item[fieldname] : fields[fieldname]['novalue'];
          else if (fieldname === 'subject_dob') {
            if (item[fieldname]) {
              const d = new Date(item[fieldname]);
              row[fields[fieldname]['heading']] = new Date(d.getTime() + d.getTimezoneOffset() * 60000);
            } else
              row[fields[fieldname]['heading']] = fields[fieldname]['novalue'];
          } else if (fieldname === 'time_accessioned')
            row[fields[fieldname]['heading']] = item[fieldname] ? new Date(item[fieldname]) : fields[fieldname]['novalue'];
          else if (fieldname === 'QC')
            row[fields[fieldname]['heading']] = item[fieldname] ? 'Acceptable' : 'Rejected';
          else if (fieldname === 'tests_performed')
            row[fields[fieldname]['heading']] = testsPerformed(item);
          else
            row[fields[fieldname]['heading']] = item[fieldname] || fields[fieldname]['novalue'];
        });
        return row;
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(preparedData);

      Object.keys(fields).forEach((fieldname, index) => {
        if (fieldname === 'subject_dob' || fieldname === 'time_accessioned') {
          const col = XLSX.utils.encode_col(index);
          // In every row after title row, set date/datetime formatting for cells in this column
          for (let rowNum = 2; rowNum <= preparedData.length + 1; rowNum++) {
            const cellAddress = col + rowNum;  // e.g. 'C3'
            const cell = worksheet[cellAddress];
            if (cell && cell.t === 'n') {   // Excel stores JS dates as numeric
              cell.z = (fieldname === 'subject_dob' ? 'mm/dd/yyyy' : 'mm/dd/yyyy hh:mm AM/PM');
            }
          }
        }
      });

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      // XLSX.writeFile(workbook, exportFilename);  if we had filename instead of handle
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array', bookSST: true });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      try {
        const writableStream = await exportFileHandle.createWritable();
        await writableStream.write(blob);
        await writableStream.close();
        setExportStatus('success');
      } catch (error) {
        console.error("Error saving file:", error);
        setExportStatus('error');
      }
    }).catch((error) => {
      console.error('accessioning/export failed', error);
      setExportStatus('error');
    });
  };

  /**
   * Present system file dialog for user to choose export Excel filename
   */
  const chooseExportFile = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const suggestedFilename = `acc_export_${year}${month}${day}.xlsx`;

    const handle = window.showSaveFilePicker
      ? window.showSaveFilePicker({
          suggestedName: suggestedFilename,
          types: [{ accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] } }],
        })
      : null;

    if (handle) {
      handle.then((fileHandle) => {
        doExport(fileHandle);
      }).catch((err) => {
        console.error("File save cancelled or error:", err);
      });
    }
  };


  //
  // Render
  //
  return (
    <>
      {/*
         Batch Entry Modal dialog
       */}
      {/* <Dialog title={typeof showBatchEntryModal === 'number' ? `View/Edit Accessioning ${showBatchEntryModal}` : 'New Accessioning'}*/}
      <Dialog title="Accessioning Entry/Edit"
              className='accessioning-modal'
              isOpen={showBatchEntryModal !== ''}
              onClose={() => {
                setShowBatchEntryModal('');
                window.location.reload();
              }}
      >
        <BatchEntryForm
          accessioningTypeOrId={showBatchEntryModal}
          onClose={() => {
            setShowBatchEntryModal('');
            window.location.reload();
          }}
        />
      </Dialog>

      {/*
        Display search results
       */}
      <Dialog
        icon="search"
        onClose={() => setIsSearchResultDialogOpen(false)}
        title="Search Results"
        isOpen={isSearchResultDialogOpen}
        style={{width:'800px'}}
      >
        <div className={Classes.DIALOG_BODY}>
          {
            searchResultBatches.length === 0 && (
              <div>
                <p><strong>Accessioning: No results</strong></p>
              </div>
            )
          }
          {
            searchResultBatches.length > 0 && (
              <div>
                <p><strong>Accessioning ({searchResultBatches.length}):</strong></p>
                <div className={'table-link table-link-stretch'}>
                  <table
                    style={{borderCollapse: 'collapse', borderSpacing: 0, marginLeft: '10px'}}
                    cellPadding={0}
                    cellSpacing={0}
                  >
                    <thead>
                    <tr>
                    <th style={{textAlign: 'center'}}>#</th>
                    <th>patient</th>
                    <th style={{textAlign: 'center'}}>dob</th>
                      <th style={{textAlign: 'center'}}>case</th>
                      <th>clinic</th>
                      <th style={{textAlign: 'center'}}>created</th>
                      <th style={{textAlign: 'center'}}># embryo</th>
                    </tr>
                    </thead>
                    {
                      searchResultBatches.map((item, index) => {
                        return (
                          <tr
                            key={'acc-' + index}
                            style={{padding: 0, cursor: 'pointer'}}
                            onClick={() => setShowBatchEntryModal(item.id)}
                          >
                            <td style={{textAlign: 'center'}}>
                              {item.id}
                            </td>
                            <td>
                              {item.patient_first_name + ' ' + item.patient_last_name}
                            </td>
                            <td style={{textAlign: 'center'}}>
                              {item.patient_dob ? displayDateMMDDYY(item.patient_dob) : '-'}
                            </td>
                            <td style={{textAlign: 'center'}}>
                              {item.case_id ? item.case_id : '-'}
                            </td>
                            <td>
                              {item.clinic_name ? item.clinic_name : '-'}
                            </td>
                            <td style={{textAlign: 'center'}}>
                              {moment.utc(item.created_at, 'YYYY-MM-DD hh:mm:ss').local().format('MM/DD/YY')}
                            </td>
                            <td style={{textAlign: 'center'}}>
                              {item.embryo_sample_count}
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>
              </div>
            )
          }
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setIsSearchResultDialogOpen(false)}>Close</Button>
          </div>
        </div>
      </Dialog>

      {/*
        Export to Excel Dialog
      */}

      <Dialog icon='th'
              title='Export to Excel Spreadsheet'
              isOpen={isExportDialogOpen}
              onClose={() => { setIsExportDialogOpen(false); }}
      >
        <div style={{marginLeft: '8px'}}>
          <br />
          <table>
            <tbody>
              <tr>
                <td style={{textAlign: 'right'}}>
                  Start Accessioning Date:
                </td>
                <td>
                  <DebouncedInput type="date" value={exportStartDate} onChange={(d) => setExportStartDate(d)} />
                </td>
              </tr>
              <tr>
                <td style={{textAlign: 'right'}}>
                  End Accessioning Date:
                </td>
                <td>
                  <DebouncedInput type="date" value={exportEndDate} onChange={(d) => setExportEndDate(d)} />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <div style={{ textAlign: "center" }}>
            {exportStatus === 'success' && <p style={{ color: 'green' }}>File exported successfully!</p>}
            {exportStatus === 'error' && <p style={{ color: 'red' }}>Error exporting file (or canceled).</p>}
            {exportStatus === 'processing' && <p>Exporting...</p>}
            {exportStatus === 'none' && <p style={{ color: 'pink', fontWeight: 'bold' }}>No matching batches found in that range</p>}
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <RSButton color="success" className='darken-on-hover' onClick={chooseExportFile}>Submit</RSButton>
            <RSButton color="warning" style={{marginLeft: '12px', marginRight: '12px'}} onClick={() => { setIsExportDialogOpen(false); }}>Cancel</RSButton>
          </div>
        </div>
      </Dialog>


      {/*
         All-Tab Accessioning Batch listing (or case-matching) screen
      */}
      {!accessioningBatchesList ? (
        <div>Loading ...</div>
        ) :
      <Card>
        {/* Header.  Contains Search, reset, +Add */}
        <CardHeader>
          <Container fluid>
            <Row>
              <Col>
                {/* Search box */}
                <form style={{display: 'inline-block'}}
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitSearchRequest(searchValue);
                  }}
                >
                  <div style={{display: "inline-block", width: "300px"}}>
                    <InputGroup
                      disabled={searchResultLoading}
                      large={false}
                      placeholder="Search case, name or dob ..."
                      rightElement={<Button type="submit" icon={'search'} style={{borderRadius: 99}}
                                            loading={searchResultLoading} minimal />}
                      small={false}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      type="search"
                    />
                  </div>
                </form>
                {/* Reset button */}
                <div className="smallWordButton" style={{marginLeft:'25px'}}
                     title="restore default sorting and remove all filtering"
                     onClick={resetAll}>
                  reset sort/filter
                </div>
                {/* Export to Excel Button */}
                <div style={{display: "inline-block", marginLeft: "2.4rem"}}>
                  <Button icon="th" title="Export to Excel Spreadsheet"
                          onClick={() => {setIsExportDialogOpen(true); }}
                          className="darken-on-hover"
                          style={{display: 'flex', alignItems: 'center'}} />
                </div>
                {/* Case-Matchup button */}
                <div style={{display: "inline-block", marginLeft: "2.4rem"}}>
                  <button
                    onClick={() => setCaseMatchup(!isCaseMatchChecked)}
                    style={{
                      padding: "8px 14px",
                      backgroundColor: isCaseMatchChecked ? "#6558f5" : "#f5f2ff",
                      color: isCaseMatchChecked ? "#fff" : "#333",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      boxShadow: isCaseMatchChecked
                        ? "inset 4px 4px 6px rgba(0,0,0,0.5)"
                        : "4px 4px 6px rgba(0,0,0,0.3)",
                      transform: isCaseMatchChecked ? "translateY(1px)" : "translateY(0)",
                      transition: "all 0.1s ease",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: isCaseMatchChecked ? "bold" : "normal",
                      minWidth: "180px"
                    }}
                  >
                    Match potential cases
                  </button>
                </div>
              </Col>
              <Col xs lg="2" className="text-right">
                {/* +Add a batch */}
                <ButtonDropdown isOpen={addBatchDropdownOpen} toggle={() => setAddBatchDropdownOpen(!addBatchDropdownOpen)}>
                  <DropdownToggle color="primary">
                    +Add
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => setShowBatchEntryModal(ACCESSIONING_TYPE__CLINICAL)}>Embryonic</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => setShowBatchEntryModal(ACCESSIONING_TYPE__OTHER_DNA)}>Other DNA</DropdownItem>
                    <DropdownItem onClick={() => setShowBatchEntryModal(ACCESSIONING_TYPE__RESEARCH)}>Research</DropdownItem>
                    <DropdownItem onClick={() => setShowBatchEntryModal(ACCESSIONING_TYPE__QC)}>QC</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
            </Row>
          </Container>
        </CardHeader>

        {/*
          Table of Accessioning Batches
          */}
        <table style={{borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray"}}>
          <thead style={{backgroundColor: '#ebe9f2'}}>
            {AllBatchTSTable.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className='accessioningTR' style={{height: "50px"}}>
                {headerGroup.headers.map((header) => {
                  const headerContext = header.getContext();
                  // Inject this into header's context so TableHeader can access it
                  headerContext.showFeatures = {
                    showColumnFeatures: showColumnFeatures,
                    setShowColumnFeatures: setShowColumnFeatures,
                  };
                  return (
                    <th key={header.id} className='accessioningTH' style={header.column.id === 'clinic' ? {textAlign: 'left'} : {}}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          headerContext,
                        )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody style={{borderBottom: "1px solid lightgray"}}>
            {AllBatchTSTable.getRowModel().rows.length === 0 ? (
              <tr>
                <td colSpan={100} style={{padding: "2rem", textAlign: "center"}}><i>No matching entries found</i></td>
              </tr>
            ) : (
              AllBatchTSTable.getRowModel().rows.map((row) => (
              <tr key={row.id} className='accessioningTR'>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} style={{height: "50px"}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            )))}
          </tbody>
        </table>

        {/*Row of paging controls beneath accessioning table*/}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'right',
          height: '50px',
          backgroundColor: '#ebe9f2'
        }}>
          <Pagination
              table={AllBatchTSTable}
              pageRange={3}
              pageOptions={AllBatchTSTable.getPageOptions()}
          >
            <Pagination.Goto table={AllBatchTSTable} options={[10, 20, 30, 40, 50]}/>
          </Pagination>
        </div>

      </Card>}
    </>
  );
};
