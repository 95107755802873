export const accessioning_sample_issues = {
  "tubevolover10ul": "Tube volume > 10 ul",
  "tubevolunder2ul": "Tube volume < 2 ul",
  "disctubecodessf": "Discrepant tube label/barcode - SSF noted",
  "disctubecode": "Discrepant tube label/barcode - NOT SSF noted",
  "tubedoa": "Tube was not intact upon arrival",
  "tubedamaged": "Striations, cracks, or scratches on tube",
  "labltubenotssf": "Labeled tube not on SSF",
  "liqtubenotssf": "Tube with liquid not on SSF",
  "tubeawol": "Tube listed on SSF but not received",
  "tubenaked": "No barcode or label on tube",
  "labeloverwrite": "Clinic wrote on 2D barcode label",
  "dupbarcode": "Duplicate GPCL Barcode ID received",
  "pgttestssf": "PGT test run SSF used",
  "otherlabssf": "Another lab's SSF used",
  "other": "other"
};