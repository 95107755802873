export const ACCESSIONING_STATUS__RECEIVED = 'received';
export const ACCESSIONING_STATUS__ACCESSIONED = 'accessioned';
export const ACCESSIONING_STATUS__PRE_AMPED = 'pre_amped';
export const ACCESSIONING_STATUS__AXIOMED = 'axiomed';

export const accessioningStatusDisplayValues = {
  'received': 'Received',
  'accessioned': 'Accessioned',
  'pre_amped': 'PreAmped',
  'axiomed': 'Axiomed'
};
