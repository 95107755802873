import React, {useState} from 'react';
// import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classNames from "classnames";
import AllTab from "./AllTab";

export default function AccessioningPage(props) {
  const [activeTab, setActiveTab] = useState('all');  // "all" or "preamp" or "axiom" ...
  const [totalSampleQuantity, setTotalSampleQuantity] = useState(0);

  return (
    <div style={{marginTop: "-20px"}}>
      <Container fluid>
        <Row>
          <Col md="6">
            <Nav className="accession-tabs">
              <NavItem>
                <NavLink
                  className={classNames({active: activeTab === 'all'})}
                  onClick={() => setActiveTab('all')}
                >
                  All
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classNames({active: activeTab === 'preamp'})}
                  onClick={() => setActiveTab('preamp')}
                >
                  preAmp
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classNames({active: activeTab === 'axiom'})}
                  onClick={() => setActiveTab('axiom')}
                >
                  Axiom
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="6" className="text-right">
            <div style={{display: "inline-block", padding: ".5rem 1rem"}}>Total # of Samples: {totalSampleQuantity.toLocaleString("en-US")}</div>
            <div className="sortFilterHelpButton"
             onClick={()=>{window.alert("- click the word 'CLINIC' in heading to sort/filter by clinic name. E.g. To show only 'ovation' clinic entries.\n" +
               "- click 'CLINIC' again to remove its sorting/filtering\n\n" +
               "You can do same with 'CASE ID', 'SAMPLE TYPE' and the other columns.\n\n" +
               "You may do more than one column. " +
               "E.g. click 'STATUS' while filtering on CLINIC.")}}>
            ?</div>
          </Col>
        </Row>
      </Container>
      <hr style={{marginTop: 0}}/>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='all'>
          <AllTab
            setTotalSampleQuantity={setTotalSampleQuantity}
          />
        </TabPane>

        <TabPane tabId='preamp'>
          <div>PreAmp Tab has not yet been implemented</div>
        </TabPane>

        <TabPane tabId='axiom'>
          <div>Axiom Tab has not yet been implemented</div>
        </TabPane>

      </TabContent>
    </div>
  );
}