export const ACCESSIONING_TYPE__CLINICAL = 'clinical';
export const ACCESSIONING_TYPE__RESEARCH = 'research';
export const ACCESSIONING_TYPE__QC = 'qc';
export const ACCESSIONING_TYPE__OTHER_DNA = 'dna';
export const ACCESSIONING_TYPE__NONE = '';

export const accessioningTypeDisplayValues = {
  'clinical': 'Clinical',
  'research': 'Research',
  'qc': 'QC',
  'dna': 'Other DNA'
};

export const accessioningTypeColorBg = {
  'clinical': '#ebf1f5',
  'research': '#f5f5eb',
  'qc': '#f8edf6'
}