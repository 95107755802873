import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {getToken} from "../../../../auth/token";
import {Menu, MenuItem, Spinner} from "@blueprintjs/core";
import classNames from 'classnames';

/**
 * ClinicDropdown component - display current clinic; let user choose clinic with dropdown
 */
const ClinicDropdown = ({setClinic, clinicDialogRef}) => {
  const [clinics, setClinics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [matchList, setMatchList] = useState([]);
  const [nameMatch, setNameMatch] = useState('');
  const MATCH_LIMIT = 15;  // maximum number of matches to show

  // Initially, load all clinics from db
  useEffect(() => {
    const fetchClinics = async () => {
      try {
        const response = await axios({
          method: 'GET',
          responseType: 'json',
          baseURL: process.env.REACT_APP_API_HOST,
          url: '/provider-list',
          headers: {
            "Authorization": `Token ${getToken()}`
          }
        });
        if ('data' in response && 'Clinics' in response.data) {
          const result = [{id: 0, company_name: 'Not Provided'}];
          result.push(...response.data.Clinics);
          setClinics(result);
        }
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch clinics', error);
        setLoading(false);
      }
    };

    fetchClinics();
  }, []);


  // Read clinics into matchList whenever user enters something (or clinic data changes)
  // nameMatch = what user inputted
  // clinics = row data from provider table
  useEffect(() => {
    const m = [];  // list of names to match against
    const d = [];  // copy of clinics data
    if (clinics) {
      for (let n = 0; n in clinics; n++) d[n] = clinics[n];
      if (!nameMatch) {  // no name search specified; just show first N items in list
        for (const clinic of d) {
          m.push(clinic);
          if (m.length >= MATCH_LIMIT) {
            setMatchList(m);
            return;
          }
        }

        setMatchList(m);
        return;
      }

      for (const clinic of d) {
        if (nameMatch && clinic.company_name.toLowerCase().indexOf(nameMatch) !== -1) {
          m.push(clinic);
          if (m.length >= MATCH_LIMIT) {
            setMatchList(m);
            return;
          }
        }
      }
    }
    setMatchList(m);
  }, [clinics, nameMatch]);

  // trigger match-list useEffect whenever user enters text in input box (and lowercase it)
  const nameChangeHandler = (e) => {
    setNameMatch(e.target.value.toLowerCase());
  }

  const keyUpHandler = (event) => {
    if (event.key === 'Enter') {
      if (matchList.length === 1) {
        setClinic(matchList[0]);
        clinicDialogRef.current.close();
      }
    } else if (event.key === 'Escape') {
      clinicDialogRef.current.close();
    }
    // else pass through key
  }

  // render
  return (
    <div>
      {loading ? (
        <Spinner color="primary" />
      ) : (
        clinics && (
          <div>
            <div style={{marginBottom: 10, marginTop: 20}}>
              Clinic Search:&nbsp;<input type="text" autoFocus value={nameMatch} onChange={nameChangeHandler}
                                         onKeyUp={keyUpHandler}/>
            </div>
            {/* Have to put close button after input, otherwise it gets initial focus instead of textbox */}
            <button style={{
                position: "absolute",
                top: "0",
                right: "3px",
                cursor: "pointer",
                fontSize: "1.2rem",
                border: "0",
                backgroundColor: "transparent"
              }} onClick={() => clinicDialogRef.current.close()}
            >
              &times;
            </button>
            {matchList.length === 0 ? <div>No matches found</div> : null}
            {matchList.length > 0 ? (
              <Menu>
                {matchList.map((clinic, index) => (
                  <MenuItem key={index}
                            text={`${clinic.company_name}`}
                            onClick={() => {
                              setClinic(clinic);
                              clinicDialogRef.current.close();
                            }}
                            className={classNames({'bp3-skeleton': loading})}
                            style={{marginBottom: 4}}
                  />
                ))}
              </Menu>
            ) : null
            }
          </div>
        ))}
    </div>
  );
};

export default ClinicDropdown;
