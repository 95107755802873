import axios from "axios";
import {axiosParams, getUserToken, TYPE__CLINIC_SSF, TYPE__GP_INTERNAL_FILES, getFileTypeFromFilename} from "./utils";
import {ACCESSIONING_TYPE__OTHER_DNA} from "./accessioningTypes";

/**
 * Link an existing accessioning batch to given case (patient).
 * Accessioning batch is retrieved and updated with new patient_id (case id).
 * This is a wrapper around linkCaseToBatch which updates the linked case (patient).
 *
 * @param accessioningBatchId
 * @param patientId
 * @param {string=} authToken - user auth token as returned from getAuthToken or similar function.  If null, function attempts to get.
 * @returns {Promise<unknown>}
 */
export const linkExistingAccessioningBatchToCase = (accessioningBatchId, patientId, authToken='') => {
  return new Promise((resolve, reject) => {
    const token = authToken ? authToken : getUserToken();
    if (!token) return reject('not authorized');

    // retrieve batch
    axios(axiosParams(`accessioning/${accessioningBatchId}`, token, null, 'GET')).then(response => {
      const accessioningBatch = response.data;
      // update case
      linkAccessioningBatchToCase(accessioningBatch, patientId, token).then(linkedAccessioningBatch => {
        // update batch with case id
        axios(axiosParams(`accessioning/${accessioningBatchId}/update_case`, token, {patient_id: patientId}, 'PATCH')).then(response => {
          resolve(linkedAccessioningBatch);
        }).catch(error => reject(error)); // error updating accessioning batch
      }).catch(error => reject(error)); // error on linkCaseToBatch
    }).catch(error => reject(error)); // error retrieving accessioning batch
  }); // end of new Promise
}

/**
 * Link given accessioning batch to given case.  This function retrieves and updates case.
 * It is assumed caller will save/update batch in db after this function resolves.
 *
 * @param {Object} accessioningBatch - new or existing accessioning batch to link
 *      This is complete object structure including samples and files
 * @param {number} patientId  - id of case (id of row in patient table)
 * @param {string=} authToken - user auth token as returned from getAuthToken or similar function.  If null, function attempts to get.
 * @returns {Promise<unknown>} - resolve: accessioning batch data with updated case (patient)
 *       Caller must save/update the batch after this function resolves
 */
export const linkAccessioningBatchToCase = (accessioningBatch, patientId, authToken= '') => {
  return new Promise((resolve, reject) => {
    const token = authToken ? authToken : getUserToken();
    if (!token) return reject('not authorized');

    // retrieve case
    axios(axiosParams(`patients/${patientId}`, token, null, 'GET')).then(response => {
      const caseData = response.data;

      if (accessioningBatch.accessioning_type === ACCESSIONING_TYPE__OTHER_DNA) {
        if (!(['patient', 'partner'].includes(accessioningBatch.subject_role))) return reject('bad role');
        caseData.saliva_samples = [...caseData.saliva_samples]  // make mutable copy
        caseData.saliva_samples.push({
          patient_id: caseData.patient_id,
          provider_id: caseData.provider_id,
          label: accessioningBatch.tube_id,
          person_id: accessioningBatch.subject_role === 'patient' ? caseData.patient_id : caseData.partner_id,
          received_at: accessioningBatch.received_at,
          person_type: accessioningBatch.subject_role,
          sample_type: accessioningBatch.sample_type
        })
      } else {  // embryonic
        caseData.samples = [...caseData.samples];  // make mutable copy of samples

        // Add accessioning Samples to case's samples
        let gpclId = '';
        if (accessioningBatch.embryo_samples.length > 0) {
          accessioningBatch.embryo_samples.forEach(sample => {
            if (!gpclId) gpclId = sample.tube_label.split('-')[0];
            if (sample.tissue_type !== 'NC' && sample.QC && sample.embryo_number !== 'NP') {
              caseData.samples.push({
                patient_id: caseData.patient_id,
                provider_id: caseData.provider_id,
                tube_label: sample.tube_label,
                embryo_number: parseInt(sample.embryo_number, 10),
                embryo_id: sample.embryo_id,
                tissue_type: sample.tissue_type,
                qc_check: sample.QC ? 'pass' : 'fail',
                // notes: sample.note,    Notes for internal use only - Shouldn't be seen by clients
                created_at: sample.created_at,
                updated_at: new Date().toISOString(),
                icsi: caseData.icsi,
                conventional_ivf: caseData.conventional_ivf
              });
            }
          });
        }

        // Set case's id
        if (!gpclId) return reject('no GPCL ID');
        caseData.case_id = gpclId;
      }

      // Add accessioning files to case's files
      if (accessioningBatch.files) {
        const ssfFilesUploaded = [];
        const gpInternalFilesUploaded = [];
        accessioningBatch.files.forEach((fileUploaded) =>
          getFileTypeFromFilename(fileUploaded.file_name) === TYPE__CLINIC_SSF ? ssfFilesUploaded.push(fileUploaded)
                                                                         : gpInternalFilesUploaded.push(fileUploaded)
        );
        if (ssfFilesUploaded) {
          const items = caseData[TYPE__CLINIC_SSF] ? JSON.parse(caseData[TYPE__CLINIC_SSF]) : [];
          caseData[TYPE__CLINIC_SSF] = JSON.stringify([...items, ...ssfFilesUploaded]);
        }
        if (gpInternalFilesUploaded) {
          const items = caseData[TYPE__GP_INTERNAL_FILES] ? JSON.parse(caseData[TYPE__GP_INTERNAL_FILES]) : [];
          caseData[TYPE__GP_INTERNAL_FILES] = JSON.stringify([...items, ...gpInternalFilesUploaded]);
        }
      }

      // save updated case, then add it to batch (caller will save/update batch)
      axios(axiosParams(`patients/${patientId}`, token, caseData, 'PUT')).then(response => {
        accessioningBatch.patient = patientId;
        resolve(accessioningBatch);
      }).catch(error => reject(error)); // error updating case
    }).catch(error => reject(error)); // error retrieving case;
  });
}