import React, {useEffect, useState} from 'react';
import AsyncSelect from 'react-select/async';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {Button, Col, FormFeedback, FormGroup, Label, Row} from "reactstrap";
import classNames from 'classnames';
import * as Yup from 'yup';
import validator from 'validator';
import * as CountriesJSON from 'components/Address/country_state_code';
import {IfAuthGC} from "components/RBAC";
import axios from "axios";
import {connect} from "react-redux";
import {getToken} from "../../auth/token";
import "./Form.css"


const Schema = Yup.object().shape({
  qtty: Yup.number()
    .min(1, 'Must be more or equal to 1')
    .required('Required'),
  swab_qtty: Yup.number()
    .min(1, 'Must be more or equal to 1')
    .required('Required'),
  extra_buffer: Yup.number()
    .min(0, 'Must be more or equal to 0'),
  phone: Yup.string()
    .test(
      'phone',
      'Phone number is not valid',
      value => value ? validator.isMobilePhone(value) : true
    ),
  shipping_address: Yup.object().shape({
    address_1: Yup.string()
      .min(2, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Required'),
    address_2: Yup.string()
      .max(120, 'Too Long!'),
    country: Yup.string()
      .required('Required'),
    state: Yup.string()
      .when('country', {
        is: (val) => val && CountriesJSON.default[val]['states'].length > 0,
        then: Yup.string().required('Required')
      }),
    city: Yup.string()
      .min(2, 'Too Short!')
      .max(255, 'Too Long!')
      .required('Required'),
    zip: Yup.string()
      .required('Required'),
  })
});

const Countries = CountriesJSON.default;

const defaultShippingAddress = {
  country: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '',
  zip: '',
};

const KitOrderForm = props => {
  const { initialValues, userRole, userId } = props
  const [outNumber, setOutNumber] = useState(false)
  const [loading, setLoading] = useState(true);

  const providerId = initialValues && initialValues.provider ? initialValues.provider
      : userRole === 'provider' ? userId
          : JSON.parse(window.localStorage.getItem('provider')).id


  useEffect(() => {
    axios({
      method: 'GET',
      data: null,
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      url: `providers/${providerId}`,
      headers: {
        "Authorization": `Token ${getToken()}`
      }
    }).then(response => {
      if ('data' in response && 'company_address' in response.data &&
                                  response.data.company_address && response.data.company_address !== '{}') {
        const companyAddress = JSON.parse(response.data.company_address);
        defaultShippingAddress.country = companyAddress.country;
        defaultShippingAddress.address_1 = companyAddress.street;
        defaultShippingAddress.address_2 = companyAddress.unit;
        defaultShippingAddress.city = companyAddress.city;
        defaultShippingAddress.state = companyAddress.state;
        defaultShippingAddress.zip = companyAddress.postal;
      }
    }).catch(error => {
      // do nothing
    }).finally(() => {
      setLoading(false);
    })
  }, [providerId]);  // eslint-disable-line react-hooks/exhaustive-deps


  const getPatientsOptions = searchWord => {
    return axios({
      method: 'POST',
      data: {
        searchWord,
        providerId
      },
      responseType: 'json',
      baseURL: process.env.REACT_APP_API_HOST,
      url: `search/provider/patients/`,
      headers: {
        "Authorization": `Token ${getToken()}`
      }
    })
  };

  const patientsOptions = inputValue => {
    return getPatientsOptions(inputValue).then(({ data: { options, outnumber } }) => {
      setOutNumber(outnumber)
      return options
    })
  }

  return loading ? <div>Please Wait</div> : (
    <Formik
      initialValues={
        {
          kit: props.kitId,
          qtty: 1,
          swab_qtty: 1,
          tracking: '',
          tracking_return: '',
          sent_barcodes: '',
          patient: '',
          recipient: '',
          phone: '',
          notes: '',
          n_barcodes: 0,
          shipping_address: {
            id: '',
            address_1: defaultShippingAddress.address_1,
            address_2: defaultShippingAddress.address_2,
            zip: defaultShippingAddress.zip,
            country: defaultShippingAddress.country,
            state: defaultShippingAddress.state,
            city: defaultShippingAddress.city
          },
          ...props.initialValues
        }
      }
      validationSchema={Schema}
      onSubmit={
        (values, {setSubmitting}) => {
          props.onSubmit(values);
          setSubmitting(false)
        }
      }
    >

      {
        ({errors, touched, values, isSubmitting, setFieldValue, setFieldTouched}) => {
        return (
            <Form autoComplete="off">
              <FormGroup row>
                <Label for="qtty" sm={4}>Quantity <span className="text-danger">*</span></Label>
                <Col sm={4}>
                  <Field
                      type="number"
                      name="qtty"
                      disabled={values.tracking_return || values.tracking}
                      className={classNames('form-control', {
                        'is-invalid': touched.qtty && !!errors.qtty
                      })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="qtty" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              {
                props.kitId === 1 && (
                    <FormGroup row>
                      <Label for="swab_qtty" sm={4}>Number of swabs <span className="text-danger">*</span></Label>
                      <Col sm={4}>
                        <Field
                            type="number"
                            name="swab_qtty"
                            disabled={values.tracking_return || values.tracking}
                            className={classNames('form-control', {
                              'is-invalid': props.kitId === 1 && touched.swab_qtty && !!errors.swab_qtty
                            })}
                        />
                        <FormFeedback>
                          <ErrorMessage name="swab_qtty" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                )
              }

              {
                (props.kitId === 2 || props.kitId === 0) && (
                    <FormGroup row>
                      <Label for="n_barcodes" sm={4}>Barcodes</Label>
                      <Col sm={4}>
                        <Field
                            component="select"
                            name="n_barcodes"
                            className={classNames('form-control', {
                              'is-invalid': touched.n_barcodes && !!errors.n_barcodes
                            })}
                        >
                          <option value={''}>Not Selected</option>
                          <option value={25}>Standard</option>
                          <option value={40}>High Capacity</option>
                        </Field>
                        <FormFeedback>
                          <ErrorMessage name="n_barcodes" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                )
              }

              {
                (props.kitId === 2 || props.kitId === 0) && (
                    <FormGroup row>
                      <Label for="extra_buffer" sm={4}>Number of extra buffer</Label>
                      <Col sm={4}>
                        <Field
                            type="number"
                            name="extra_buffer"
                            className={classNames('form-control', {
                              'is-invalid': touched.extra_buffer && !!errors.extra_buffer
                            })}
                        />
                        <FormFeedback>
                          <ErrorMessage name="extra_buffer" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                )
              }

              <hr/>

              <h6>Ship To</h6>

              <FormGroup row className="patient-select">
                <Label sm={4}>
                </Label>
                <Col sm={8}>
                  <div className={classNames('invalid-feedback', {
                    'd-block': touched.patient && outNumber
                  })}>
                    <span>Only first 50 patients are displayed. Please refine your search</span>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="patient" sm={4}>Patient</Label>
                <Col sm={8}>
                  <div onClick={() => setFieldTouched("patient", true)}>
                    <AsyncSelect
                        classNamePrefix='patient-select'
                        isClearable
                        defaultOptions
                        defaultValue={props.initialValues && props.initialValues.patient &&
                                      {
                                        value: props.initialValues.patient,
                                        label: props.initialValues.patient
                                      }
                        }
                        loadOptions={patientsOptions}
                        onChange={newValue => {
                            if (newValue) {
                              setFieldValue('patient', newValue.value);
                              if (newValue.address) {
                                setFieldValue('shipping_address.country', newValue.address.country);
                                setFieldValue('shipping_address.address_1', newValue.address.address_1);
                                setFieldValue('shipping_address.address_2', newValue.address.address_2);
                                setFieldValue('shipping_address.city', newValue.address.city);
                                setFieldValue('shipping_address.state', newValue.address.state);
                                setFieldValue('shipping_address.zip', newValue.address.zip);
                              }
                            } else {
                              setFieldValue('patient', '');
                            }
                          }
                        }
                    />
                  </div>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="recipient" sm={4}>Recipient (Full Name)</Label>
                <Col sm={8}>
                  <Field
                      type="text"
                      name="recipient"
                      disabled={values.tracking_return || values.tracking}
                      className={classNames('form-control', {
                        'is-invalid': touched.recipient && !!errors.recipient
                      })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="recipient" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="phone" sm={4}>Phone Number</Label>
                <Col sm={8}>
                  <Field
                      type="text"
                      name="phone"
                      disabled={values.tracking_return || values.tracking}
                      className={classNames('form-control', {
                        'is-invalid': touched.phone && !!errors.phone
                      })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="phone" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <hr/>

              <FormGroup row>
                <Label for="shipping_address.country" sm={4}>Country <span className="text-danger">*</span></Label>
                <Col sm={8}>
                  <Field
                      component="select"
                      name="shipping_address.country"
                      disabled={values.tracking_return || values.tracking}
                      className={
                        classNames(
                            'form-control', {
                              'is-invalid': touched.shipping_address &&
                                  errors.shipping_address &&
                                  touched.shipping_address.country &&
                                  !!errors.shipping_address.country
                            }
                        )
                      }
                  >
                    <option value={''} key={'country-none'} disabled>Select Country</option>
                    {
                      Object.keys(Countries).map((code, i) => {
                        return <option value={code} key={'country-' + i}>{Countries[code].name}</option>
                      })
                    }
                  </Field>
                  <FormFeedback>
                    <ErrorMessage name="shipping_address.country" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="shipping_address.address_1" sm={4}>Address Line 1 <span
                    className="text-danger">*</span></Label>
                <Col sm={8}>
                  <Field
                      type="text"
                      name="shipping_address.address_1"
                      disabled={values.tracking_return || values.tracking}
                      className={
                        classNames(
                            'form-control', {
                              'is-invalid': touched.shipping_address &&
                                  touched.shipping_address.address_1 &&
                                  errors.shipping_address &&
                                  !!errors.shipping_address.address_1
                            }
                        )
                      }
                  />
                  <FormFeedback>
                    <ErrorMessage name="shipping_address.address_1" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="shipping_address.address_2" sm={4}>Address Line 2</Label>
                <Col sm={8}>
                  <Field
                      type="text"
                      name="shipping_address.address_2"
                      disabled={values.tracking_return || values.tracking}
                      className={
                        classNames(
                            'form-control', {
                              'is-invalid': touched.shipping_address &&
                                  touched.shipping_address.address_2 &&
                                  errors.shipping_address &&
                                  !!errors.shipping_address.address_2
                            }
                        )
                      }
                  />
                  <FormFeedback>
                    <ErrorMessage name="shipping_address.address_2" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="shipping_address.city" sm={4}>City <span className="text-danger">*</span></Label>
                <Col sm={8}>
                  <Field
                      type="text"
                      name="shipping_address.city"
                      disabled={values.tracking_return || values.tracking}
                      className={
                        classNames(
                            'form-control', {
                              'is-invalid': touched.shipping_address &&
                                  errors.shipping_address &&
                                  touched.shipping_address.city &&
                                  !!errors.shipping_address.city
                            }
                        )
                      }
                  />
                  <FormFeedback>
                    <ErrorMessage name="shipping_address.city" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>


              {
                values.shipping_address.country && Countries[values.shipping_address.country] && Countries[values.shipping_address.country]['states'].length ? (
                    <FormGroup row>
                      <Label for="shipping_address.state" sm={4}>State/Province/Region <span
                          className="text-danger">*</span></Label>
                      <Col sm={8}>
                        <Field
                            component="select"
                            name="shipping_address.state"
                            className={
                              classNames(
                                  'form-control', {
                                    'is-invalid': touched.shipping_address &&
                                        errors.shipping_address &&
                                        touched.shipping_address.state &&
                                        !!errors.shipping_address.state
                                  }
                              )
                            }
                            // disabled={!values.shipping_address.country}
                            disabled={values.tracking_return || values.tracking}
                        >
                          <option value={''} key={'state-none'} disabled>Select...</option>
                          {
                            Countries[values.shipping_address.country]['states'].map((item, i) => {
                              return <option key={'state-' + i}>{item}</option>
                            })
                          }
                        </Field>
                        <FormFeedback>
                          <ErrorMessage name="shipping_address.state" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>
                ) : ''
              }

              <FormGroup row>
                <Label for="shipping_address.zip" sm={4}>Zip Code <span className="text-danger">*</span></Label>
                <Col sm={8}>
                  <Field
                      type="text"
                      name="shipping_address.zip"
                      disabled={values.tracking_return || values.tracking}
                      className={
                        classNames(
                            'form-control', {
                              'is-invalid': touched.shipping_address &&
                                  errors.shipping_address &&
                                  touched.shipping_address.zip &&
                                  !!errors.shipping_address.zip
                            }
                        )
                      }
                  />
                  <FormFeedback>
                    <ErrorMessage name="shipping_address.zip" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <IfAuthGC>
                <hr/>

                <FormGroup row>
                  <Label for="tracking" sm={4}>Tracking Number</Label>
                  <Col sm={8}>
                    <Field
                        type="text"
                        name="tracking"
                        className={classNames('form-control', {
                          'is-invalid': touched.tracking && !!errors.tracking
                        })}
                    />
                    <FormFeedback>
                      <ErrorMessage name="tracking" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="tracking_return" sm={4}>Return Tracking Number</Label>
                  <Col sm={8}>
                    <Field
                        type="text"
                        name="tracking_return"
                        className={classNames('form-control', {
                          'is-invalid': touched.tracking_return && !!errors.tracking_return
                        })}
                    />
                    <FormFeedback>
                      <ErrorMessage name="tracking_return" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="sent_barcodes" sm={4}>Sent Barcodes</Label>
                  <Col sm={8}>
                    <Field
                        type="text"
                        name="sent_barcodes"
                        className={classNames('form-control', {
                          'is-invalid': touched.tracking_return && !!errors.tracking_return
                        })}
                    />
                    <FormFeedback>
                      <ErrorMessage name="sent_barcodes" component="span"/>
                    </FormFeedback>
                  </Col>
                </FormGroup>
              </IfAuthGC>

              <hr/>

              <FormGroup row>
                <Label for="notes" sm={4}>Notes</Label>
                <Col sm={8}>
                  <Field
                      component="textarea"
                      name="notes"
                      className={classNames('form-control', {
                        'is-invalid': touched.notes && !!errors.notes
                      })}
                  />
                  <FormFeedback>
                    <ErrorMessage name="notes" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

            <Row style={{marginTop: 20}} className="justify-content-start">
              <Col md="auto">
                <Button
                    type="submit"
                    disabled={
                      isSubmitting || !!Object.keys(errors).length ||
                      (!props.initialValues  && !Object.keys(touched).length)
                    }
                    color="success"
                >
                  Submit
                </Button>
              </Col>
              <Col md="auto">
                <Button color="light" onClick={() => props.onCancel()}>Cancel</Button>
              </Col>
            </Row>

            </Form>
        )}
      }
    </Formik>
  )
}

export default connect(
    state => ({
      userRole: state.auth.role,
      userId: state.auth.id,
    })
)(KitOrderForm);

