import React from "react";
import {DebouncedInput} from "../../common/utils";

/**
 * Display filter-input component for given column.
 * Column's filterVariant meta-data specifies what type of input component.
 * @param column - tanstack column object
 * @returns {JSX.Element} - filter input component to display in column's heading.
 *                          Component will set column's filter value when utilized by user.
 */
export function Filter({ column }) {
  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?  column.columnDef.meta : {};

  // tanstack numeric range example
  // return filterVariant === 'range' ? (
  //   <div>
  //     <div className="flex space-x-2">
  //       {/* See faceted column filters example for min max values functionality */}
  //       <DebouncedInput
  //         type="number"
  //         value={(columnFilterValue)?.[0] ? (columnFilterValue)[0] : ''}
  //         onChange={value =>
  //           column.setFilterValue((old) => [value, old?.[1]])
  //         }
  //         placeholder={`Min`}
  //         className="w-24 border shadow rounded"
  //       />
  //       <DebouncedInput
  //         type="number"
  //         value={(columnFilterValue)?.[1] ? (columnFilterValue)[1] : ''}
  //         onChange={value =>
  //           column.setFilterValue((old) => [old?.[0], value])
  //         }
  //         placeholder={`Max`}
  //         className="w-24 border shadow rounded"
  //       />
  //     </div>
  //     <div className="h-1" />
  //   </div>

  // Date-Range.  Display 'from' and 'to' date input components
  if (filterVariant === 'date-range') return (
    <div>
      <table style={{border: 0}}>
        <tbody>
          <tr>
            <td style={{fontWeight: 'normal', fontSize: '75%'}}>from</td>
            <td>
              <DebouncedInput
                type="date"
                value={(columnFilterValue && columnFilterValue[0]) ? (columnFilterValue)[0] : ''}
                onChange={value =>
                  column.setFilterValue(old => [value, ((old && old[1]) ? old[1] : null)])
                }
                className="w-24 border shadow rounded"
              />
            </td>
          </tr>
          <tr style={{border: 0}}>
            <td style={{fontWeight: 'normal', fontSize: '75%', textAlign: 'right'}}>to</td>
            <td>
              <DebouncedInput
                type="date"
                value={(columnFilterValue && columnFilterValue[1]) ? (columnFilterValue)[1] : ''}
                onChange={value =>
                  column.setFilterValue(old => [(old && old[0]) ? old[0] : null, value])
                }
                className="w-24 border shadow rounded"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="h-1" />
    </div>
  );

  // Sample-Type column
  if (filterVariant === 'select_sample_type') return (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue ? columnFilterValue.toString() : null}
    >
      <option value="">All</option>
      <option value="embryonic">Embryonic</option>
      <option value="saliva">Saliva</option>
      <option value="dna">DNA</option>
      <option value="other">other</option>
    </select>
  );

  // Accessioning-Type column
  if (filterVariant === 'select_type') return (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue ? columnFilterValue.toString() : null}
    >
      <option value="">All</option>
      <option value="clinical">Clinical</option>
      <option value="research">Research</option>
      <option value="qc">QC</option>
      <option value="dna">Other DNA</option>
      <option value="unprocessed">Unprocessed</option>
    </select>
  );

  // Accessioning Status column
  if (filterVariant === 'select_status') return (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue ? columnFilterValue.toString() : null}
    >
      <option value="">All</option>
      <option value="draft">draft</option>
      <option value="accessioned">accessioned</option>
      <option value="preamp-queued">preamp-queued</option>
      <option value="preamp-complete">preamp-complete</option>
      <option value="axiom-queued">axiom-queued</option>
      <option value="axiom-complete">axiom-complete</option>
    </select>
  );

  // Clinic and other columns
  return (
    <DebouncedInput
      className="w-36 border shadow rounded"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ? columnFilterValue : '')}
    />
    // tanstack - See faceted column filters example for datalist search suggestions
  );
}
