export const ACCESSIONING_SUBJECT__PATIENT = 'patient';
export const ACCESSIONING_SUBJECT__PARTNER = 'partner';
export const ACCESSIONING_SUBJECT__RELATIVE = 'relative';
export const ACCESSIONING_SUBJECT__DONOR = 'donor';
export const ACCESSIONING_SUBJECT__UNKNOWN = 'unknown';
export const ACCESSIONING_SUBJECT__OTHER = 'other';

export const ACCESSIONING_RELATIONSHIP__DAUGHTER = 'daughter';
export const ACCESSIONING_RELATIONSHIP__SON = 'son';
export const ACCESSIONING_RELATIONSHIP__MOTHER = 'mother';
export const ACCESSIONING_RELATIONSHIP__FATHER = 'father';
export const ACCESSIONING_RELATIONSHIP__SISTER = 'sister';
export const ACCESSIONING_RELATIONSHIP__BROTHER = 'brother';
export const ACCESSIONING_RELATIONSHIP__AUNT = 'aunt';
export const ACCESSIONING_RELATIONSHIP__UNCLE = 'uncle';
export const ACCESSIONING_RELATIONSHIP__COUSIN_FEMALE = 'cousin_female';
export const ACCESSIONING_RELATIONSHIP__COUSIN_MALE = 'cousin_male';
