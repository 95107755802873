import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {Button, Col, FormFeedback, FormGroup, Label} from "reactstrap";
import classNames from 'classnames';
import * as Yup from 'yup';
import Date from 'utils/Formik/Date';
import {YOUNGEST_YEAR} from 'utils/Formik/DateOfBirth';
import {withRouter} from 'react-router-dom';
import {IfAuthSuper} from "components/RBAC";


const Schema = Yup.object().shape({
  label: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  collection_date: Yup.string()
    .required('Required'),
  person: Yup.object().nullable().shape({
    first_name: Yup.string()
      .max(255, 'Too Long!'),
    last_name: Yup.string()
      .max(255, 'Too Long!'),
  })
});

export default withRouter((props) => (
    <Formik
      initialValues={
        {
          label: '',
          collection_date: '',
          sent_at: undefined,
          received_at: undefined,
          patient: '',
          case: '',
          case_name: '',
          person_type: '',
          person: {
            first_name: '',
            last_name: '',
            dob: `${YOUNGEST_YEAR()}-01-01`,
            related_to: '',
            related_as: ''
          },
          ...props.initialValues
        }
      }
      validationSchema={Schema}
      onSubmit={
        (values, {setSubmitting}) => {
          props.onSubmit(values);
          setSubmitting(false);
          console.log(values)
        }
      }
    >

      {
        ({errors, touched, values, setFieldValue, isSubmitting}) => {
          const getPatientPartner = () => {
            if (props.cases && values.patient) {
              const arr = props.cases.filter((item) => {
                return item.id === parseInt(values.patient)
              });

              return [
                'Patient: ' + (arr[0].patient ? arr[0].patient.first_name + ' ' + arr[0].patient.last_name : ''),
                'Partner: ' + (arr[0].partner ? arr[0].partner.first_name + ' ' + arr[0].partner.last_name : '')
              ]
            }

            return []
          };

          return (
            <Form>
              <FormGroup row>
                <Label for="label" sm={4}>Your Reference Label <span className="text-danger">*</span></Label>
                <Col sm={8}>
                  <Field
                    type="text"
                    name="label"
                    className={classNames('form-control', {'is-invalid': touched['label'] && errors['label']})}
                  />
                  <FormFeedback>
                    <ErrorMessage name="label" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              <IfAuthSuper>
                <FormGroup row>
                  <Label for="sent_at" sm={4}>Kit Sent</Label>
                  <Col sm={8}>
                    <input
                      type="date"
                      name="sent_at"
                      className={classNames('form-control', {'is-invalid': touched['sent_at'] && errors['sent_at']})}
                      onChange={(d) => {setFieldValue('sent_at', d.target.value)}}
                      value={values.sent_at || undefined}
                    />
                    <ErrorMessage name="sent_at" component="span" className="error form-error"/>
                  </Col>
                </FormGroup>
              </IfAuthSuper>

              <FormGroup row>
                <Label for="collection_date" sm={4}>Date of Collection <span className="text-danger">*</span></Label>
                <Col sm={8}>
                  <input
                    type="date"
                    name="collection_date"
                    className={classNames('form-control', {'is-invalid': touched['collection_date'] && errors['collection_date']})}
                    onChange={(d) => {setFieldValue('collection_date', d.target.value)}}
                    value={values.collection_date || undefined}
                  />
                  <ErrorMessage name="collection_date" component="span" className="error form-error"/>
                </Col>
              </FormGroup>

              <IfAuthSuper>
                <FormGroup row>
                  <Label for="received_at" sm={4}>Kit Received</Label>
                  <Col sm={8}>
                    <input
                      type="date"
                      name="received_at"
                      className={classNames('form-control', {'is-invalid': touched['received_at'] && errors['received_at']})}
                      onChange={(d) => {setFieldValue('received_at', d.target.value)}}
                      value={values.received_at || undefined}
                    />
                    <ErrorMessage name="received_at" component="span" className="error form-error"/>
                  </Col>
                </FormGroup>
              </IfAuthSuper>

              <hr/>

              <h6 className="text-muted">Case</h6>

              <FormGroup row>
                <Label for="patient" sm={4}>Select Case</Label>
                <Col sm={8}>
                  <Field
                    component="select"
                    name="patient"
                    className={classNames('form-control', {'is-invalid': touched.patient && errors.patient})}
                  >
                    <option value={''}>Select...</option>
                    {
                      props.cases.filter(item => !!item.patient && !!item.partner).map((item, i) =>
                        <option key={'case-option-' + item.id} value={item.id}>
                          {item.patient.first_name} {item.patient.last_name},{' '}
                          {item.partner.first_name} {item.partner.last_name}{' '}
                          {item.case_id && `(${item.case_id})`}
                        </option>
                      )
                    }
                  </Field>
                  <FormFeedback>
                    <ErrorMessage name="patient" component="span"/>
                  </FormFeedback>
                </Col>
              </FormGroup>

              {
                values.patient && (
                  <div>
                    <FormGroup row>
                      <Label for="person_type" sm={4}>Person</Label>
                      <Col sm={8}>
                        <Field
                          component="select"
                          name="person_type"
                          className={classNames('form-control', {
                            'is-invalid': touched.person_type && errors.person_type
                          })}
                        >
                          <option value={''}>Select</option>
                          <option value="patient">{getPatientPartner()[0]}</option>
                          <option value="partner">{getPatientPartner()[1]}</option>
                          <option value="other">Other</option>
                        </Field>
                      </Col>
                    </FormGroup>
                  </div>
                )
              }

              {
                (values.patient && values.person_type === 'other') && (
                  <div>
                    <FormGroup row>
                      <Label for="person.first_name" sm={4}>First Name</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="person.first_name"
                          className={classNames('form-control', {
                            'is-invalid': touched.person && touched.person.first_name && errors.person && !!errors.person.first_name
                          })}
                        />
                        <FormFeedback>
                          <ErrorMessage name="person.first_name" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="person.last_name" sm={4}>Last Name</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="person.last_name"
                          className={classNames('form-control', {
                            'is-invalid': touched.person && touched.person.last_name && errors.person && !!errors.person.last_name
                          })}
                        />
                        <FormFeedback>
                          <ErrorMessage name="person.last_name" component="span"/>
                        </FormFeedback>
                      </Col>
                    </FormGroup>

                    <FormGroup row>
                      <Label for="person.dob" sm={4}>Date of Birth</Label>
                      <Col sm={8}>
                        <Field
                          type="text"
                          name="person.dob"
                          className={classNames('form-control', {
                            'is-invalid': touched.person && errors.person && touched.person.dob && errors.person.dob
                          })}
                          component={Date}
                          youngest={-1}
                        />
                        <ErrorMessage name="person.dob" component="span" className="error form-error"/>
                      </Col>
                    </FormGroup>

                    <div>
                      <FormGroup row>
                        <Label for="person.related_to" sm={4}>Related To</Label>
                        <Col sm={8}>
                          <Field
                            name="person.related_to"
                            className={classNames('form-control', {
                              'is-invalid': touched.related_to && errors.related_to && touched.person.related_to && errors.person.related_to
                            })}
                            component="select"
                          >
                            <option value={''}>Select</option>
                            {
                              getPatientPartner().map((item, i) => (
                                <option key={'rel-opt-' + i} value={item}>{item}</option>
                              ))
                            }
                          </Field>
                          <ErrorMessage name="person.related_to" component="span" className="error form-error"/>
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label for="person.related_as" sm={4}>Related As</Label>
                        <Col sm={8}>
                          <Field
                            name="person.related_as"
                            className={classNames('form-control', {
                              'is-invalid': touched.related_as && errors.related_as && touched.person.related_as && errors.person.related_as
                            })}
                            component="select"
                          >
                            <option value={''}>Select</option>
                            <option value={'daughter'}>Daughter</option>
                            <option value={'son'}>Son</option>
                            <option value={'mother'}>Mother</option>
                            <option value={'father'}>Father</option>
                            <option value={'sister'}>Sister</option>
                            <option value={'brother'}>Brother</option>
                            <option value={'aunt'}>Aunt</option>
                            <option value={'uncle'}>Uncle</option>
                            <option value={'cousin_female'}>Cousin (female)</option>
                            <option value={'cousin_male'}>Cousin (male)</option>
                          </Field>
                          <ErrorMessage name="person.related_as" component="span" className="error form-error"/>
                        </Col>
                      </FormGroup>
                    </div>
                  </div>
                )
              }

              <FormGroup className="text-center" style={{marginTop: 30}}>
                <Button
                  type="submit"
                  disabled={isSubmitting || !!errors.length}
                  color="success"
                  outline
                >
                  {props.initialValues && props.initialValues.id ? 'Save Changes' : 'Add Sample'}
                </Button>
              </FormGroup>
            </Form>
          )
        }
      }
    </Formik>
  )
)
