/** Tanstack Table Column definitions for All-tab table */

import React, { useState, useRef, useEffect } from "react";
import TableHeader from "./TableHeader";
import {createColumnHelper} from "@tanstack/react-table";
import {displayDateMMDDYY, dateRangeFilterFn, displaySampleType, displayAccessioningType} from "../common/utils";
import '../accessioning.css';

// remove the filter value from filter state if it is falsy (empty string in this case)
dateRangeFilterFn.autoRemove = val => !val;

// Define the columns of the tanstack table using tanstack ColumnHelper
//
// .accessor - columns linked to data model that can be filtered, sorted, etc.
// .display - display-only columns not linked to data.  May contain controls.
// [not used] .grouping - non-data columns used for grouping.  e.g. header, footer ...
const columnHelper = createColumnHelper();
export function getColumnDefinitions(handleCaseLink, getLinkingInProgress, handleEdit, handleDelete, handleLog) {
  return [
    columnHelper.accessor("id", {
      id: "id",
      header: ({ column, showFeatures }) =>
        <TableHeader title="Accessioning Id" column={column} showFeatures={showFeatures} hint="sorting/searching"/>,
      cell: (info) =>
        <div className="accessioningId" title="view/edit this batch" onClick={() => { handleEdit(info.getValue())}}>
          {info.getValue()}
        </div>,
      enableSorting: true,
      // invertSorting: true,
      // sortDescending: true,
      // sortDescFirst: true,
      enableColumnFilter: true,
      filterFn: 'equalsString'  // searches must match exact number
    }),
    columnHelper.accessor("case_id", {
      id: "case_id",
      header: ({ column, showFeatures}) =>
        <TableHeader title="Case Id" column={column} showFeatures={showFeatures} hint="sorting/searching" />,
      cell: ({ getValue }) => {
        const v = getValue();
        if (!v) return '';
        const part = v.split('#'); // values comes from db as:  GPCL-case-id#patient-id#provider-id
        return (<div style={{textAlign: "center"}}><a href={`../patients/${part[1]}?provider=${part[2]}`}
                                                      title="Open case in new window"
                                                      target="_blank" rel="noopener noreferrer">
          {part[0] ? part[0] : `#${part[1]}`}
        </a></div>);
      },
      enableSorting: true,
      enableColumnFilter: true,
      filterFn: 'includesString'
    }),
    columnHelper.accessor("potential_case_id", {
      id: "potential_case_id",
      header: ({ column, showFeatures}) =>
        <TableHeader title="Match Case Id" column={column} showFeatures={showFeatures} hint="sorting/searching" />,
      cell: (info) => {
        const v = info.getValue();
        if (!v) return '';
        const unpacked = v.split('#'); // values comes from db as:  GPCL-case-id#patient-id#provider-id
        return (
          <>
            <div style={{marginRight: "1rem", textAlign: "right", padding: "6px", backgroundColor: "#fef", borderRadius: "10px"}}>
              <a href={`../patients/${unpacked[1]}?provider=${unpacked[2]}`}
                 title="Open case in new tab"
                 target="_blank" rel="noopener noreferrer"
              >
                {unpacked[0] ? unpacked[0] : `#${unpacked[1]}`}
              </a>
              <button className="linkButton batch-case-link-button"
                      disabled={getLinkingInProgress()}
                      title="Link case to batch" onClick={() => {handleCaseLink(info, unpacked[1])}}>
                <span role='img' aria-label='link'>&#x1f517;</span>
              </button>
            </div>
          </>
        );
      },
      enableSorting: true,
      enableColumnFilter: true,
      filterFn: 'includesString'
    }),
    columnHelper.accessor("clinic", {
      id: "clinic",
      header: ({ column, showFeatures }) =>
        <TableHeader title="Clinic" column={column} showFeatures={showFeatures} hint="sorting/searching" />,
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableColumnFilter: true,
      filterFn: 'includesString'  // searches can match any part of clinic name - case-insensitive
    }),
    columnHelper.accessor("sample_type", {
      id: "sample_type",
      header: ({ column, showFeatures }) =>
        <TableHeader title="Sample Type" column={column} showFeatures={showFeatures} hint="filter"/>,
      cell: (info) =>
        <div style={{textAlign: "center"}}>
          {displaySampleType(info.getValue())}
        </div>,
      enableSorting: false,
      enableColumnFilter: true,
      meta: {
        filterVariant: 'select_sample_type',
      },
    }),
    columnHelper.accessor("accessioning_type", {
      id: "accessioning_type",
      header: ({ column, showFeatures }) =>
        <TableHeader title="Type" column={column} showFeatures={showFeatures} hint="filter" />,
      cell: (info) =>
        <div style={{textAlign: "center"}} className={`accessioning-type-${info.getValue()}`}>
          {displayAccessioningType(info.getValue())}
        </div>,
      enableSorting: false,
      enableColumnFilter: true,
      meta: {
        filterVariant: 'select_type',
      },
    }),
    columnHelper.accessor("received_at", {
      id: "received_at",
      header: ({ column, showFeatures }) =>
        <TableHeader title="Received" column={column} showFeatures={showFeatures} hint="date-range filter" />,
      cell: (info) => <div style={{textAlign: "center"}}>{displayDateMMDDYY(info.getValue())}</div>,
      enableSorting: true,
      enableColumnFilter: true,
      meta: {
        filterVariant: 'date-range',
      },
      filterFn: dateRangeFilterFn
    }),
    columnHelper.accessor("updated_at", {
      id: "updated_at",
      header: ({ column, showFeatures }) =>
        <TableHeader title="Last Modified" column={column} showFeatures={showFeatures} hint="date-range filter" />,
      cell: (info) => <div style={{textAlign: "center"}}>{displayDateMMDDYY(info.getValue())}</div>,
      enableSorting: true,
      enableColumnFilter: true,
      meta: {
        filterVariant: 'date-range',
      },
      filterFn: dateRangeFilterFn
    }),
    columnHelper.accessor("preamp_date", {
      id: "preamp_date",
      header: ({ column, showFeatures }) =>
        <TableHeader title="PreAmped" column={column} showFeatures={showFeatures} hint="date-range filter" />,
      cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
      enableSorting: true,
      enableColumnFilter: true,
      meta: {
        filterVariant: 'date-range',
      },
      filterFn: dateRangeFilterFn
    }),
    columnHelper.accessor("axiom_date", {
      id: "axiom_date",
      header: ({ column, showFeatures }) =>
        <TableHeader title="Axiomed" column={column} showFeatures={showFeatures} hint="date-range filter" />,
      cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
      enableSorting: true,
      enableColumnFilter: true,
      meta: {
        filterVariant: 'date-range',
      },
      filterFn: dateRangeFilterFn
    }),
    columnHelper.accessor("accessioning_status", {
      id: "accessioning_status",
      header: ({ column, showFeatures }) =>
        <TableHeader title="Status" column={column} showFeatures={showFeatures} hint="filter" />,
      cell: (info) => <div style={{textAlign: "center", color: "#04962A"}}>{info.getValue()}</div>,
      enableSorting: false,
      enableColumnFilter: true,
      meta: {
        filterVariant: 'select_status',
      },
    }),
    columnHelper.accessor("sample_quantity", {
      id: "sample_quantity",
      header: ({ column, showFeatures }) =>
        <TableHeader title="Samples" column={column} showFeatures={showFeatures} />,
      cell: (info) => <div style={{textAlign: "center"}}>{info.getValue()}</div>,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    columnHelper.display({
      id: "kebab_menu",
      header: () => null,
      cell: (info) => {
        const KebabMenuCell = ({ row }) => {
          const [kebabMenuIsOpen, setKebabMenuIsOpen] = useState(false);
          const kebabDropdownRef = useRef(null);

          const handleClick = () => setKebabMenuIsOpen(!kebabMenuIsOpen);
          const handleClickOutside = (event) => {
            if (kebabDropdownRef.current && !kebabDropdownRef.current.contains(event.target)) {
              setKebabMenuIsOpen(false);
            }
          };

          useEffect(() => {
            document.addEventListener("mousedown", handleClickOutside);
            return () => document.removeEventListener("mousedown", handleClickOutside);
          }, []);

          // &#8942; is three vertical dots
          return (
            <div style={{ position: "relative" }}>
              <button className="kebabButton" style={{ marginRight: "35px", fontSize: "1.2rem" }} onClick={handleClick}>
                &nbsp;&#8942;&nbsp;
              </button>
              {kebabMenuIsOpen && (
                <div ref={kebabDropdownRef} className="kebab-dropdown" style={{ position: "absolute", right: "25px", top: "25px", background: "white", border: "1px solid #ccc", padding: "5px", zIndex: 10 }}>
                  <button className="kebab-dropdown-item" onClick={() => { handleEdit(row.original); setKebabMenuIsOpen(false); }}>Edit</button>
                  <button className="kebab-dropdown-item" style={{color: "grey"}} onClick={() => { handleDelete(row.original); setKebabMenuIsOpen(false); }}>Delete</button>
                  <button className="kebab-dropdown-item" style={{color: "grey"}} onClick={() => { handleLog(row.original); setKebabMenuIsOpen(false); }}>Log</button>
                </div>
              )}
            </div>
          );
        };
        return <KebabMenuCell row={info.row} />;
      },
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];
}
